import React from "react";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface SortByProps {
    value: string;
    onChange?: (event: SelectChangeEvent) => void;
}

const SortBy: React.FC<SortByProps> = ({ value, onChange }) => {
    return (
        <Select
            labelId="sort-by-label"
            id="sort-by"
            value={value}
            onChange={onChange}
            style={{ fontSize: '.8rem' }}
        >
            <MenuItem value={'newest'} style={{ fontSize: '.8rem' }}>Newest</MenuItem>
            <MenuItem value={'price_low_high'} style={{ fontSize: '.8rem' }}>Price (Low to High)</MenuItem>
            <MenuItem value={'price_high_low'} style={{ fontSize: '.8rem' }}>Price (High to Low)</MenuItem>
        </Select>
    );
};

export default SortBy;