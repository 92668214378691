import { createContext } from "react";
import { AuthState, initialAuthState, User } from "./auth-state";
import { AxiosInstance, AxiosResponse } from "axios";

export interface AuthContextInterface<TUser extends User = User>
    extends AuthState<TUser> {
    createUser: (
        username: string,
        email: string,
        password1: string,
        password2: string,
        onError: (errorMessage: string) => void
    ) => void;
    login: (username: string, email: string, password: string) => Promise<AxiosResponse<any>>;
    logout: () => void;
    // refreshToken: () => Promise<AxiosResponse<any>>;
    getClient: () => AxiosInstance;
    getUserData: () => Promise<void>;
}

const stub = (): never => {
    throw new Error("You forgot to wrap your component in <AuthProvider>.");
};

const initialContext = {
    ...initialAuthState,
    createUser: stub,
    login: stub,
    logout: stub,
    // refreshToken: stub,
    getClient: stub,
    getUserData: stub,
};

const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;