import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import MediaCard from './InfinityCarouselCard';
import 'react-multi-carousel/lib/styles.css'; // Import carousel styles
import { Product } from '../interfaces/Product';
import { REST_INVENTORY_URL } from '../routes/restapi';

const MultiCarousel: React.FC = () => {
    const [products, setProducts] = useState([] as Product[]);
    const [randomIndices, setRandomIndices] = useState([] as number[]);

    useEffect(() => {
        const query_url = REST_INVENTORY_URL + `?size=50`;
        axios.get(`${query_url}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setProducts(response.data["results"]);
                setRandomIndices(getRandomUniqueIndices(response.data["results"].length, 12));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const getRandomUniqueIndices = (total: number, count: number) => {
        const indices = new Set<number>();
        while (indices.size < count) {
            const newIndex = Math.floor(Math.random() * total);
            if (!indices.has(newIndex)) {
                indices.add(newIndex);
            }
        }
        return Array.from(indices);
    };

    const carouselProducts = randomIndices.map(index => products[index]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            partialVisibilityGutter: 40 // this is optional if you are not using partialVisible props
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
        }
    };

    return (
        <Carousel
            infinite={true}
            responsive={responsive}
        >
            {carouselProducts.map((product, index) => (
                <MediaCard key={product.id} product={product} />
            ))}
        </Carousel>
    );
}

export default MultiCarousel;