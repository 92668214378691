import React from 'react';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Product } from "../interfaces/Product";


interface MediaCardProps {
    product: Product;
}

function MediaCard(props: MediaCardProps) {

    const product = props.product;
    const navigate = useNavigate();

    const handleClick = (productId: number) => {
        navigate(`/items/${productId}`);
    };

    return (
        <Card key={product.id} style={{ margin: "0 20px" }} onClick={() => handleClick(product.id)}>
            <CardActionArea>
                <CardMedia image={product.images.length > 0 ? product.images[0].image : 'https://via.placeholder.com/150'}
                    title={product.name} style={{ height: 200 }} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {product.name}
                    </Typography>
                    <Typography component="p">{product.description}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Typography variant="body1" color="textSecondary">
                    <Button color="inherit" >Share</Button>
                    <Button color="inherit" >Learn More</Button>
                </Typography>
            </CardActions>
        </Card>
    );
}
export default MediaCard;