import React from "react";
import { Slider } from "@mui/material";
import { useTheme } from '@mui/material';

interface PriceSliderFilterProps {
    value: number[];
    onChange: (event: Event, newValue: number | number[]) => void;
}

function valuetext(value: number) {
    return `$${value}`;
}

const PriceSliderFilter: React.FC<PriceSliderFilterProps> = ({ value, onChange }) => {
    const minPrice = 1;
    const maxPrice = 30;
    // eslint-disable-next-line
    const theme = useTheme();
    return (
        <div>
            <Slider
                id="price-range-slider"
                value={value}
                getAriaValueText={valuetext}
                onChange={onChange}
                valueLabelDisplay="off"
                min={minPrice}
                max={maxPrice}
                step={1}
                marks={[value[0], value[1]].map((value) => ({
                    value,
                    label: `$${value}`,
                }))}
            />
        </div>
    );
};

export default PriceSliderFilter;