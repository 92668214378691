import { Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import MultiCarousel from "../components/InfinityCarousel";
import { useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function MainPage() {
    const banner_images = ["banner1.png", "banner2.png"];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <div id="main-page-div" style={{ backgroundColor: theme.palette.primary.main }}>
                <div style={{ paddingTop: 50, paddingLeft: 10, paddingRight: 10, paddingBottom: 50 }}>
                    <MultiCarousel />
                </div>
            </div>
        );
    }
    else {
        return (
            <div id="main-page-div" style={{ backgroundColor: theme.palette.primary.main }}>
                <div>
                    <Grid id="main-page-grid-container" container spacing={2}>
                        <Grid item xs={12}>
                            <Carousel interval={3000} animation="slide" indicators={false} navButtonsAlwaysInvisible={true}>
                                {banner_images.map((image, index) => (
                                    <img key={index} src={`${image}`} alt={image} style={{ width: '100%', height: '500px' }} />
                                ))}
                            </Carousel>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ paddingTop: 50, paddingLeft: 100, paddingRight: 100, paddingBottom: 50 }}>
                    <MultiCarousel />
                </div>
            </div>
        );
    }
}
