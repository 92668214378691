import React from "react";
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

interface CheckboxFilterValues {
    id: string
    label: string
    value: boolean
}

interface CheckboxFilterProps {
    optionValues: CheckboxFilterValues[];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({ optionValues, onChange }) => {
    return (
        <div>
            <FormControl component="fieldset">
                {optionValues.map(option => (
                    <FormControlLabel
                        key={option.id}
                        control={
                            <Checkbox
                                checked={option.value}
                                onChange={onChange}
                                name={option.label}
                                value={option.label}
                                color="primary"
                            />
                        }
                        label={option.label}
                    />
                ))}
            </FormControl>
        </div>
    );
};

export default CheckboxFilter;
export type { CheckboxFilterValues, CheckboxFilterProps };