import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortBy from '../components/SortBy';
import { Product } from '../interfaces/Product';
import ProductsGrid from '../components/ProductsGrid';
import CheckboxFilter from '../components/CheckboxFilter';
import PriceSliderFilter from '../components/PriceSliderFilter';
import { CheckboxFilterValues } from '../components/CheckboxFilter';
import { Category } from '../interfaces/Category';
import { REST_INVENTORY_URL, REST_CATEGORY_URL, REST_COLLECTION_URL } from '../routes/restapi';
import { useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from '../components/SearchBar';

const CollectionsPage: React.FC = () => {
    const [products, setProducts] = useState([] as Product[]);
    const [sortBy, setSortBy] = useState('newest');
    const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState('');
    const [previousPage, setPreviousPage] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState('' as string);
    // eslint-disable-next-line
    const [categories, setCategories] = useState([] as Category[]);
    const [minPrice, setMinPrice] = useState(1);
    const [maxPrice, setMaxPrice] = useState(30);
    const [searchText, setSearchText] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filter, setFilter] = useState(
        [] as CheckboxFilterValues[]
    );
    const [collections, setCollections] = useState([] as CheckboxFilterValues[]);

    const handleSearchTextChange = (text: string) => {
        setSearchText(text);
        // You can also directly call your API query function here using the text
    };

    const handleNextPage = () => {
        if (nextPage) {
            setCurrentPage(currentPage + 1)
            // Call API to fetch the next page...
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            setCurrentPage(currentPage - 1)
            // Call API to fetch the previous page...
        }
    };

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setPageSize(Number(event.target.value));
        // Call API to fetch the first page with the new page size...
    };

    const setCategoriesFunction = (categories: Category[]) => {
        setCategories(categories);
        setFilter(categories.map(category => {
            return { id: String(category.id), label: category.name, value: false };
        }
        ));
    }

    const onPriceSelectChange = async (event: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setMinPrice(newValue[0]);
            setMaxPrice(newValue[1]);
        }
    }

    const onSortByChange = (event: SelectChangeEvent) => {
        setSortBy(event.target.value as string);

        if (event.target.value === 'newest') {
            setOrdering('created');
        } else if (event.target.value === 'price_low_high') {
            setOrdering('price');
        } else if (event.target.value === 'price_high_low') {
            setOrdering('-price');
        }
    }

    const onFilterChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFilter = filter.map(option => {
            if (option.label === event.target.name) {
                return { ...option, value: event.target.checked };
            }
            return option;
        });

        setFilter(updatedFilter);
    }

    const onCollectionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCollections = collections.map(option => {
            if (option.label === event.target.name) {
                return { ...option, value: event.target.checked };
            }
            return option;
        });

        setCollections(updatedCollections);
    }

    useEffect(() => {
        let url_query = '';
        const collectionNames = collections
            .filter(collection => collection.value === true)
            .map(collection => collection.label);
        const categoryNames = filter
            .filter(category => category.value === true)
            .map(category => category.label);

        if (searchText) {
            url_query = REST_INVENTORY_URL + '?search=' + searchText;
        }
        else {
            url_query = REST_INVENTORY_URL + '?size=' + pageSize + '&page=' + currentPage + '&ordering=' + ordering + '&collections__name=' +
                collectionNames.join(',') + '&categories__name=' + categoryNames.join(',');
        }
        axios.get(`${url_query}`, {
            params: {
                min_price: minPrice,
                max_price: maxPrice
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setTotalNumberOfProducts(response.data["count"]);
                setNextPage(response.data["next"]);
                setPreviousPage(response.data["previous"]);
                setProducts(response.data["results"]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [minPrice, maxPrice, filter, currentPage, pageSize, ordering, collections, searchText]);

    useEffect(() => {
        axios.get(`${REST_CATEGORY_URL}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setCategoriesFunction(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${REST_COLLECTION_URL}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setCollections(response.data.map((collection: any) => {
                    return { id: String(collection.id), label: collection.name, value: false };
                }));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (isMobile) {
        return (
            <div style={{ padding: 16, backgroundColor: theme.palette.primary.main }}>
                <Grid container spacing={2}>
                    <Grid item xs={10.5}>
                        <Grid item xs={12}>
                            <SearchBar onSearchTextChange={handleSearchTextChange} />
                            {/* Dropdown for sorting options below the image */}
                            <Box sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" sx={{ display: 'inline-block', marginRight: 2 }}>
                                    Sort by:
                                </Typography>
                                <SortBy value={sortBy} onChange={onSortByChange} />
                            </Box>
                            {/* Text on the left to display number of cutting boards */}
                            <Box sx={{ textAlign: 'left' }} >
                                <Typography variant="body2" sx={{ display: 'inline-block', marginRight: 2 }}>
                                    {Math.min(pageSize * currentPage, totalNumberOfProducts)} of {totalNumberOfProducts} products
                                </Typography>
                            </Box>
                        </Grid>
                        {/* Grid of cutting boards below the sorting dropdown */}
                        <Box sx={{ paddingTop: 2 }}>
                            <ProductsGrid products={products} />
                            {/* <CuttingBoardsGrid cuttingBoards={filteredCuttingBoards} /> */}
                        </Box>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="body2" sx={{ display: 'inline-block', margin: 2 }}>
                        Page size:
                    </Typography>
                    <Select id="page-size" value={String(pageSize)} onChange={handlePageSizeChange} style={{ fontSize: '1rem' }}>
                        <MenuItem value={'10'}>10</MenuItem>
                        <MenuItem value={'20'}>20</MenuItem>
                        <MenuItem value={'30'}>30</MenuItem>
                        <MenuItem value={'50'}>50</MenuItem>
                    </Select>
                    <br />
                    <Button variant="contained" color="primary" onClick={handlePreviousPage} disabled={!previousPage} style={{ marginLeft: 20 }}>
                        Previous
                    </Button>
                    <Typography variant="body2" sx={{ display: 'inline-block', margin: 2 }}>
                        Page {currentPage}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleNextPage} disabled={!nextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }
    else {
        return (
            <div style={{ padding: 16, backgroundColor: theme.palette.primary.main }}>
                <Grid container spacing={2}>
                    {/* Filter Column on the left */}
                    <Grid item xs={1.5}>
                        <SearchBar onSearchTextChange={handleSearchTextChange} />
                        <Paper sx={{ padding: 2 }}>
                            <Typography variant="h6">Filter By</Typography>
                            <hr />
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Categories</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CheckboxFilter optionValues={filter} onChange={onFilterChange} />
                                </AccordionDetails>
                            </Accordion>
                            <hr />
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Price</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PriceSliderFilter value={[minPrice, maxPrice]} onChange={onPriceSelectChange} />
                                </AccordionDetails>
                            </Accordion>
                            <hr />
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Collections</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CheckboxFilter optionValues={collections} onChange={onCollectionChange} />
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Grid>
                    <Grid item xs={10.5}>
                        <Box>
                            <img src="/banner1.png" alt="Wide" style={{ width: '100%', maxHeight: 400 }} />
                        </Box>
                        <Grid item xs={12}>

                            {/* Dropdown for sorting options below the image */}
                            <Box sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" sx={{ display: 'inline-block', marginRight: 2 }}>
                                    Sort by:
                                </Typography>
                                <SortBy value={sortBy} onChange={onSortByChange} />
                            </Box>
                            {/* Text on the left to display number of cutting boards */}
                            <Box sx={{ textAlign: 'left' }} >
                                <Typography variant="body2" sx={{ display: 'inline-block', marginRight: 2 }}>
                                    {Math.min(pageSize * currentPage, totalNumberOfProducts)} of {totalNumberOfProducts} products
                                </Typography>
                            </Box>
                        </Grid>
                        {/* Grid of cutting boards below the sorting dropdown */}
                        <Box sx={{ paddingTop: 2 }}>
                            <ProductsGrid products={products} />
                            {/* <CuttingBoardsGrid cuttingBoards={filteredCuttingBoards} /> */}
                        </Box>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="body2" sx={{ display: 'inline-block', margin: 2 }}>
                        Page size:
                    </Typography>
                    <Select id="page-size" value={String(pageSize)} onChange={handlePageSizeChange} style={{ fontSize: '1rem' }}>
                        <MenuItem value={'10'}>10</MenuItem>
                        <MenuItem value={'20'}>20</MenuItem>
                        <MenuItem value={'30'}>30</MenuItem>
                        <MenuItem value={'50'}>50</MenuItem>
                    </Select>
                    <br />
                    <Button variant="contained" color="primary" onClick={handlePreviousPage} disabled={!previousPage} style={{ marginLeft: 20 }}>
                        Previous
                    </Button>
                    <Typography variant="body2" sx={{ display: 'inline-block', margin: 2 }}>
                        Page {currentPage}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleNextPage} disabled={!nextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }
}


export default CollectionsPage;