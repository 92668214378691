// SearchBar.tsx
import React, { useState } from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
    onSearchTextChange: (text: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchTextChange }) => {
    const [searchBoxVisible, setSearchBoxVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    const toggleSearchBox = () => setSearchBoxVisible(!searchBoxVisible);
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newText = e.target.value;
        setSearchText(newText);
        onSearchTextChange(newText); // Invoke the callback function whenever search text changes
    };

    return (
        <>
            <IconButton onClick={toggleSearchBox}>
                <SearchIcon />
            </IconButton>
            {searchBoxVisible && (
                <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Collections"
                        inputProps={{ 'aria-label': 'search collections' }}
                        value={searchText}
                        onChange={handleSearch}
                    />
                </Paper>
            )}
        </>
    );
};

export default SearchBar;