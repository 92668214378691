import './App.css';
import { AppNavbar } from './navbar/navbar';
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/Theme';
import CollectionsPage from './pages/CollectionsPage';
import MainPage from './pages/MainPage';
import ProductDetailPage from './pages/ProductDetailPage';
import { LoginScreen } from './pages/LoginPage';
import { RegisterScreen } from './pages/RegisterPage';
import { AuthProvider } from './authentication/auth-provider';

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CssBaseline />
          <AppNavbar app_name="Leslie Clay Creations" />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/collections" element={<CollectionsPage />} />
            <Route path="/items/:productId" element={<ProductDetailPage />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
          </Routes>
          <CssBaseline />
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
