import React, { useCallback, useContext, useMemo, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext, { AuthContextInterface } from "./auth-context";
import { reducer } from "./reducer";
import { initialAuthState, User } from "./auth-state";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
    TOKEN_LOCALSTORAGE_KEY,
} from "./auth";
import { AUTH_REGISTRATION_URL, HOST_URL } from "../routes/restapi";

interface AuthProviderOptions {
    children?: React.ReactNode;
}

let refreshPromise: Promise<AxiosResponse<any, any>> | null = null;

export const AuthProvider = (opts: AuthProviderOptions): JSX.Element => {
    const { children } = opts;
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "v8HNb8WjBStvlpSZSMbLhNHbUcUtFeK7fFDRyCPO";
    const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || "6p31RHx4h2rTcshOGK15GJZH3RhHVBOaCKIYMomXs9wLNx6fy2MnrlWuET58vGywD18Bie609OwXbU7vzssf94NFTjel84el56GfSaAe6nWx8jJWGK5huKAMA6VDahiI";

    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const navigate = useNavigate();

    const refreshToken = useCallback((): Promise<AxiosResponse<any>> => {
        const local = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            if (local === null) {
                reject("already refreshing or localstorage token not found");
                return;
            }
            const token = JSON.parse(local);
            const url = HOST_URL + "/o/token/";
            const request = axios.post(
                url,
                new URLSearchParams({
                    refresh_token: token.refresh_token,
                    grant_type: "refresh_token",
                    client_id: CLIENT_ID,
                    client_secret: CLIENT_SECRET,
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            request
                .then((res) => {
                    const token = JSON.stringify(res.data);
                    localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
                    resolve(res);
                })
                .catch((err) => {
                    navigate("/login", { replace: true });
                    reject(err);
                });
        });
    }, [CLIENT_ID, CLIENT_SECRET, navigate]);

    const getClient = useCallback((): AxiosInstance => {
        const newInstance = axios.create();
        newInstance.interceptors.request.use((config) => {
            const local = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
            if (!config.url?.includes(HOST_URL)) config.url = HOST_URL + config.url;
            dispatch({ type: "LOADING", value: true });
            if (local === null) return config;
            const token = JSON.parse(local).key;
            if (token && config.headers !== undefined) {
                config.headers.set("www-Authenticate", `Token`);
                //config.headers.set("Accept-Encoding", "gzip, deflate, br");
                config.headers.set("Authorization", `Token ${token}`);
                config.headers.set("Accept", "*/*");
                config.headers.set("Content-Type", "application/x-www-form-urlencoded");
                //config.headers.set("Access-Control-Allow-Headers", "Authorization");
                //config.headers.Authorization = `Token ${token}`;
            }
            return config;
        });
        newInstance.interceptors.response.use(
            (response) => {
                dispatch({ type: "LOADING", value: false });
                return response;
            },
            async (error) => {
                dispatch({ type: "LOADING", value: false });
                const originalRequest = error.config;
                if (error.response === undefined) return Promise.reject(error);
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    if (refreshPromise === null) {
                        const prom = refreshToken().then((res) => {
                            refreshPromise = null;
                            return res;
                        });
                        refreshPromise = prom;
                        await prom;
                    } else {
                        await refreshPromise;
                    }

                    return newInstance(originalRequest);
                }
                return Promise.reject(error);
            }
        );
        return newInstance;
    }, [refreshToken]);

    const getUserData = useCallback(async () => {
        const client = getClient();
        const local = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
        if (local === null) {
            return;
        }
        // If user data is already in the state, return it
        if (state.user) {
            return;
        }
        const response = await client.get("/o/user/");

        // Update the user data in the state
        dispatch({
            type: "USER_DATA",
            payload: { username: response.data.username },
        });
        return;
    }
        , [getClient, state.user]);

    const login = useCallback(
        async (username: string, email: string, password: string): Promise<AxiosResponse<any>> => {
            const url = HOST_URL + "/o/login/";
            try {
                const res = await axios.post(
                    url,
                    new URLSearchParams({
                        username: username,
                        email: email,
                        password: password,
                        grant_type: "password",
                    }),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                );
                const token = JSON.stringify(res.data);
                localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
                await getUserData();
                navigate("/", { replace: true });
                return res;
            } catch (err) {
                throw err;
            }
        },
        [navigate, getUserData]
    );

    const logout = useCallback(async () => {
        const client = getClient();
        const local = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
        if (local === null) {
            return;
        }
        await client.post("/o/logout/");

        // Update the user data in the state
        dispatch({
            type: "LOGOUT",
            payload: {},
        });
        localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
        navigate("/", { replace: true });
    }
        , [navigate, getClient]);

    const createUser = useCallback(
        async (
            username: string,
            email: string,
            password1: string,
            password2: string,
            onError: (errorMessage: string) => void
        ) => {
            try {
                const response = axios.post(
                    AUTH_REGISTRATION_URL,
                    {
                        username,
                        email,
                        password1,
                        password2,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                await response;
                navigate("/login", { replace: true });
                // Handle success (e.g., navigate to login or dashboard)
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    console.error(error.response);
                    onError(`Unable to create user. Please try again.`);
                } else {
                    onError("An unexpected error occurred.");
                }
            }
        },
        [navigate]
    );

    useEffect(() => {
        const local = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
        if (local) {
            getUserData().then(() => {
            }).catch(error => {
                console.error("Failed to fetch user data", error);
            });
        }
    }, [getUserData]);

    const contextValue = useMemo(() => {
        return {
            ...state,
            refreshToken,
            login,
            logout,
            createUser,
            getClient,
            getUserData,
        };
    }, [state, refreshToken, login, logout, createUser, getClient, getUserData]);

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = <
    TUser extends User = User
>(): AuthContextInterface<TUser> =>
    useContext(AuthContext) as AuthContextInterface<TUser>;