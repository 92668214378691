import { AuthState } from "./auth-state";

type Action =
    | {
        type: "LOADING";
        value: boolean;
    }
    | {
        type: "LOGIN";
        payload: { username: string; };
    }
    | {
        type: "LOGOUT";
        payload: {};
    }
    | {
        type: "USER_DATA";
        payload: { username: string; };
    };

export const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                isLoading: action.value,
            };
        case "LOGIN":
            return {
                ...state,
                user: {
                    id: 0,
                    email: "",
                    first_name: "",
                    last_name: "",
                    username: action.payload.username,
                },
            };
        case "LOGOUT":
            return {
                ...state,
                user: undefined,
            };
        case "USER_DATA":
            return {
                ...state,
                user: {
                    id: 0,
                    email: "",
                    first_name: "",
                    last_name: "",
                    username: action.payload.username,
                },
            };
        default:
            return state;
    }
};