import { createTheme } from '@mui/material/styles';

// declare module '@mui/material/styles' {
//   interface Theme {
//     palette: {
//       primary: {
//         main: string;
//       };
//       secondary: {
//         main: string;
//       };
//     };
//   }
//   interface ThemeOptions {
//     paletteOptions?: {
//       primary?: {
//         main?: string;
//       };
//       secondary?: {
//         main?: string;
//       };
//     };
//   }
// }

export const theme = createTheme({
  palette: {
    primary: {
      main: '#bdbdbd',
    },
    secondary: {
      main: '#2979ff',
    },
    text: {
      primary: '#000000',
      secondary: '#c3a30d',
    },
  },
});

