import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Product } from '../interfaces/Product';

interface ProductsGridProps {
    products: Product[];
}

const ProductsGrid: React.FC<ProductsGridProps> = ({ products }) => {

    const navigate = useNavigate();
    const handleClick = (productId: number) => {
        navigate(`/items/${productId}`);
    };

    return (
        <div>
            <Grid container spacing={2} justifyContent={"center"}>
                {products.map((product: Product) => (
                    <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                        <Card onClick={() => handleClick(product.id)} sx={{
                            ':hover': {
                                boxShadow: 20, // theme.shadows[20]
                            },
                        }}>
                            <CardMedia
                                component="img"
                                height="400"
                                image={product.images.length > 0 ? product.images[0].image : 'https://via.placeholder.com/150'} // Display placeholder image if no image is available
                                alt={product.name}
                            />
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {product.name}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Price: ${product.price}
                                </Typography>
                                {/* Add more details as needed */}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ProductsGrid;