import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useTheme } from "@mui/material";
import { Product, ProductImage } from "../interfaces/Product";
import { REST_INVENTORY_URL } from '../routes/restapi';
import './ProductDetailPage.scss';

const ProductDetailPage: React.FC = () => {
    const [product, setProduct] = useState({} as Product);
    const { productId } = useParams<{ productId: string }>();

    const theme = useTheme();

    useEffect(() => {
        axios.get(`${REST_INVENTORY_URL}${productId}/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                console.log('Data fetched:', response.data);
                setProduct(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [productId]);

    if (!product) {
        return <div>Loading...</div>;
    }
    else {
        console.log('Product:', product)
        // print all data from the product
        console.log('Product ID:', product.id);
        console.log('Product Name:', product.name);
        console.log('Product Description:', product.description);
        console.log('Product SKU:', product.sku);
        console.log('Product Quantity:', product.quantity);
        console.log('Product Price:', product.price);
        console.log('Product Height:', product.height);
        console.log('Product Width:', product.width);
        console.log('Product Weight:', product.weight);
        console.log('Product Images:', product.images);
    }


    return (
        <div className="detailed-product-view" style={{ backgroundColor: theme.palette.primary.main }}>
            <div className="side-panel left"></div>
            <div className="detailed-product-content">
                <Typography variant="h4" gutterBottom align="center">
                    {product.name}
                </Typography>
                <div className="detailed-product-carousel">
                    {/* Display carousel if product.images is defined */}
                    {product.images && product.images.length > 0 ? (
                        <Carousel>
                            {product.images.map((image: ProductImage, index: number) => (
                                <Card key={index}>
                                    <CardMedia component="img" image={`${image.image}`} alt={product.name} />
                                </Card>
                            ))}
                        </Carousel>
                    ) : (
                        <Card>
                            <CardMedia component="img" image="https://via.placeholder.com/300" alt={product.name} />
                        </Card>
                    )}
                </div>
                <Card className="details" >
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {product.description}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Price: ${product.price}
                        </Typography>
                        {/* Add other details as needed */}
                    </CardContent>
                </Card>
            </div>
            <div className="side-panel right"></div>
        </div>

    );
}

export default ProductDetailPage;