import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ReactElement } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from "@mui/material";
import { useAuth } from "../authentication/auth-provider";

interface AppNavbarProps {
  app_name: string;
}

export function AppNavbar({ app_name }: AppNavbarProps): ReactElement {
  const theme = useTheme();
  const { user, logout } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const mobileOpen = Boolean(mobileMoreAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const handleLogout = () => {
    logout();
    handleClose();
  };

  if (isMobile) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              id="basic-button"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMobileMenuOpen}
              aria-controls={mobileOpen ? 'menu' : undefined}
              aria-haspopup="true"
              aria-expanded={mobileOpen ? 'true' : undefined}
            >
              <MenuIcon />
            </IconButton>
            <Menu id="menu" anchorEl={mobileMoreAnchorEl} open={mobileOpen} onClose={handleMobileMenuClose} MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
              <MenuItem onClick={handleMobileMenuClose} component={Link} to="/">Home</MenuItem>
              <MenuItem onClick={handleMobileMenuClose} component={Link} to="/collections">Collections</MenuItem>
              <MenuItem onClick={handleMobileMenuClose}>Locations</MenuItem>
              <MenuItem onClick={handleMobileMenuClose}>About</MenuItem>
            </Menu>
            <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
              <img src="logo192.png" alt="logo" style={{ maxWidth: '40%', height: '40%' }} />
            </div>
            {user ? (
              <>
                <Button color="inherit" onClick={handleClick}>
                  {user.username}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
  else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <img src="/logo192.png" alt="logo" style={{ width: '50px', height: '50px' }} />
            </IconButton>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                {app_name}
              </Link>
            </Typography>
            <Typography sx={{ flexGrow: 1 }}>
              <Button color="inherit" component={Link} to="/">Home</Button>
              <Button color="inherit" component={Link} to="/collections">Collections</Button>
              <Button color="inherit">Locations</Button>
              <Button color="inherit">About</Button>
            </Typography>
            {user ? (
              <>
                <Button color="inherit" onClick={handleClick}>
                  {user.username}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}